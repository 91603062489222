import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/utils/PageTitle";

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <div style={{ height: "100vh", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <PageTitle title="404 Not Found" />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
